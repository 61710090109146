<!-- 隔离登记建档  By阿旭 -->
<template>
  <div class="main">
    <div v-if="!isShowDetail">
        <div class="condition-filtrate-box">
        <div class="left-item">
          <el-input class="select-width id-code-width" size="medium" v-model="queryForm.searchValue"
            placeholder="居民姓名/手机号/证件号" @keyup.enter.native="query()" />

          <el-select class="select-width" size="medium" v-model="queryForm.cardType" placeholder="证件类型" @keyup.enter.native="query()">
            <el-option v-for="(item, v) in typeCertificate" :key="v" :label="item.label" :value="item.value"></el-option>
          </el-select>

          <el-select class="select-width" size="medium" v-model="queryForm.country" clearable filterable placeholder="国籍" @keyup.enter.native="query()">
            <el-option v-for="(item, v) in nationalitySelect" :key="v" :label="item.label" :value="item.label" />
          </el-select>

          <el-select v-btn-permission="'008001001001'" class="select-width" size="medium" clearable
            v-model="queryForm.isolationPointId" placeholder="隔离点" @keyup.enter.native="query()">
            <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id"/>
          </el-select>

          <el-select class="select-width" size="medium" v-model="queryForm.inspectStatus" placeholder="核查状态" @keyup.enter.native="query()">
            <el-option v-for="(item, v) in checkstatusselect" :key="v" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-date-picker style="width: 260px" v-model="timetime" unlink-panels size="medium" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
        </div>

        <div class="right-item">
          <el-button type="primary" size="small" @click="query('btn')">查询</el-button>
          <el-button type="primary" size="small" @click="reset()">重置</el-button>
          <el-button type="primary" size="small" @click="newadd('add')" v-btn-permission="'008001001002'">新增</el-button>
          <el-button type="primary" size="small" @click="exportListData" v-loading.fullscreen.lock="fullscreenLoading">导出
          </el-button>
        </div>
      </div>

      <TableModel :is-show-select="true" :is-show-tabs="false" :loading="loading" :table-data="tableData"
        table-size="medium">
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-link type="primary" @click="searchDetaile(scope.row)" v-btn-permission="'008099'" v-if="scope.row.regChannel!='0'&& scope.row.regChannel!='1'">查看信息</el-link>
            <el-link type="primary" v-btn-permission="'008001001003'" v-if="scope.row.inspectStatus == 0"
              @click="newadd(scope.row)">核查处理</el-link>
            <el-link type="primary" v-btn-permission="'008001001004'" @click="sendnotice(scope.row)"
              v-if="scope.row.regChannel == 1">发送告知书</el-link>
            <el-link type="primary" v-btn-permission="'008001001005'" @click="dictionaryFun(scope.row)"
              v-else>打印告知书</el-link>

            <el-link type="primary" v-btn-permission="'008001001006'" @click="confirmoperation(scope.row)">确认签署</el-link>
            <el-link type="primary" v-btn-permission="'008001001007'" @click="arrange(scope.row)">安排隔离</el-link>
            <el-link type="primary" v-btn-permission="'008001001008'" @click="delItemData(scope.row)">删除</el-link>
            <el-link type="primary" v-btn-permission="'008001001009'" @click="glzd(scope.row)">转隔离点</el-link>

          </template>
        </el-table-column>

        <el-table-column prop="name" label="居民信息"></el-table-column>
        <el-table-column prop="cardType" label="证件类型">
          <template slot-scope="scope">
            <div style="white-space: nowrap" v-if="scope.row.cardType == 1">居民身份证</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 2">护照</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 3">港澳通行证</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 4">台胞证</div>
            <div style="white-space: nowrap" v-if="scope.row.cardType == 5">其他</div>
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="证件号" width="190" />
        <el-table-column prop="isolationPointName" label="隔离点" width="160" />
        <el-table-column prop="country" label="国籍"></el-table-column>
        <el-table-column prop="regChannel" label="登记渠道">
          <template slot-scope="scope">
            <span v-if="scope.row.regChannel == 0">现场</span>
            <span v-if="scope.row.regChannel == 1">小程序</span>
            <span v-if="scope.row.regChannel == 2">酒店转进</span>
            <span v-if="scope.row.regChannel == 3">流调转进</span>
            <span v-if="scope.row.regChannel == 4">居家隔离转进</span>
            <span v-if="scope.row.regChannel == 5">居家监测转进</span>
            <span v-if="scope.row.regChannel == 6">街镇转进</span>
            <span v-if="scope.row.regChannel == 7">转隔离点转进</span>
          </template>
        </el-table-column>
        <el-table-column prop="crowdTypeName" label="人群分类" width="170" />
        <el-table-column prop="regOrgName" label="推送机构" width="160" />
        <el-table-column prop="regUserInfo" label="推送人" width="200" />
        <el-table-column prop="regTime" label="登记时间" width="170" />

        <el-table-column prop="gzs" label="告知书状态">
          <template slot-scope="scope">
            <span v-if="scope.row.entryBookSignStatus == 0">未签署</span>
            <span v-if="scope.row.entryBookSignStatus == 1">已签署</span>
          </template>
        </el-table-column>
        
        <el-table-column prop="hc" label="核查状态">
          <template slot-scope="scope">
            <span v-if="scope.row.inspectStatus == 0">未核查</span>
            <span v-if="scope.row.inspectStatus == 1">已核查</span>
          </template>
        </el-table-column>
      </TableModel>

      <div class="content_page">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
          :total="total" :page-sizes="[20, 30, 50, 100]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    

    <!-- 确认签署弹窗 -->
    <el-dialog title="确认操作" :visible.sync="isConfirmoperation" width="500px" :before-close="closeConfirmoperation">
      <p style="padding: 0 20px; line-height: 26px;">请确认居民已签署隔离告知书，确认签署后点击确认将视为完成告知书签署！</p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeConfirmoperation">取 消</el-button>
        <el-button size="small" type="primary" @click="adsd()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 安排隔离 弹窗 -->
    <ArrangeIsolateDialog ref="ArrangeIsolateRef" dialog-type="安排隔离" />

    <!--删除弹窗-->
    <DelResidentDialog ref="DelResidentDialogRef" dialog-title="删除信息" />

    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>

    <!-- 转隔离点 -->
    <!-- 方法使用了，但是未被定义！ 先注释掉（jzy） -->
    <!-- <el-dialog title="转隔离点" :visible.sync="isgldd" width="30%" :before-close="handleClose"> -->
      <el-dialog title="转隔离点" :visible.sync="isgldd" width="30%">
      <div class="gleddvi">
        隔离点：<el-select v-model="dialogisolationPointId" placeholder="请选择">
          <el-option v-for="item in allIsolationList" :key="item.id" :label="item.orgName" :value="item.id" />

        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="glddialogqx()">取 消</el-button>
        <el-button type="primary" @click="glddialogqd()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  downloadEntryBook,
  regList,
  sendEntrySign,
  confirmEntrySign,
  exportRegListApi,
  replaceIsolationPoint
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import {
  getAllIsolationOrgList,
} from "../../api/SystemManagement/index.js";
import constants from "../../common/constants";
import TableModel from "../../components/TableModel";
import DelResidentDialog from "./modules/DelResidentDialog";
import ArrangeIsolateDialog from "./modules/ArrangeIsolateDialog";
import { messageBox, importFile } from "../../utils/utils";
import { mapState } from "vuex";
import residentInformation from "./residentInformation";

export default {
  components: { TableModel, ArrangeIsolateDialog, DelResidentDialog ,residentInformation,},
  data() {
    return {
      isShowDetail:false,
      loading: false,
      isgldd: false,
      dialogisolationPointId: '',
      fullscreenLoading: false,
      queryForm: {
        searchValue: "",
        cardType: "",
        country: "",
        inspectStatus: "",
        isolationPointId: ""
      }, //查询表单
      typeCertificate: constants.TYPE_CERTIFICATE, //身份证类型
      checkstatusselect: constants.CHECK_STATUS_SELECT2, //核查状态
      tableData: [], //表单数据
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      qdiddd: "",
      isConfirmoperation: false, //确认签署弹窗
      timetime: [],
      allIsolationList: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles"))
    };
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList, // 隔离点列表数据
      nationalitySelect: state => state.user.nationalitySelect // 国籍
    })
  },

  created() {
    this.getListInfo();
    this.allIsolationListFun();
  },

  methods: {
    // 查看居民详情
    searchDetaile(row) {
      console.log(row,'666666666');
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row)
      })
    },
    // 所有隔离点列表
    async allIsolationListFun() {
      try {
        let { data } = await getAllIsolationOrgList();
        this.allIsolationList = data.data;
      } catch (error) {}
    },
    // 安排隔离
    arrange(row) {
      this.$refs.ArrangeIsolateRef.show(row);
    },
    // 转隔离点
    glzd(v) {
      this.qdiddd = v.id;

      this.isgldd = true
    },
    // 签署弹窗
    confirmoperation(v) {
      this.qdiddd = v.id;
      this.isConfirmoperation = true;
    },

    // 删除信息
    delItemData(row) {
      this.$refs.DelResidentDialogRef.show(row.id);
    },

    // 关闭签署弹窗
    closeConfirmoperation() {
      this.isConfirmoperation = false;
    },

    // 发送报告
    async sendnotice(v) {
      let params = { 
        regId: v.id
      };
      let { data } = await sendEntrySign({ params });
      if (data.code == 200) {
        this.$notify({
          title: "告知书发送",
          message: `${data.msg}`,
          dangerouslyUseHTMLString: true
        });
      } else {
        this.$message.error(data.msg);
      }
    },
    // 新增 / 核查处理
    newadd(row) {
      // console.log(row);
      this.$router.push({
        path: "/ohealth/isolationManagement/newregistration",
        query: { rowdata: row } //跳转传参
      });
    }, 
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.query();
    },

    // 设置导出请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let {
        searchValue,
        country,
        cardType,
        inspectStatus,
        isolationPointId
      } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (country) params.country = country;
      if (cardType) params.cardType = cardType;
      if (inspectStatus) params.inspectStatus = inspectStatus;
      if (isolationPointId) params.isolationPointId = isolationPointId;

      if (this.timetime && this.timetime.length == 2) {
        params.regTimeMin = this.timetime[0] + " 00:00:00";
        params.regTimeMax = this.timetime[1] + " 23:59:59";
      }

      return params;
    },

    // 查询
    query(type) {
      this.getListInfo(type);
    },

    // 查询列表
    async getListInfo(type) {
      this.loading = true;
      let params = this.setParamsInfo();
      if(type=='btn'){
          params.pageNumber = 1;
        }
      let result = await regList({ params });
      // console.log(result);
      let { code, data, msg } = result.data;
      if (code == 200) {
        let { totalRow, list } = data;
        this.tableData = list;
        console.log(this.tableData);
        this.total = totalRow;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    // 重置
    reset() {
      this.timetime = [];
      this.queryForm = {
        searchValue: "",
        cardType: "",
        country: "",
        inspectStatus: "",
        isolationPointId: ""
      };
      this.getListInfo();
    },

    // 导出列表数据
    exportListData() {
      let params = this.setParamsInfo();
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportRegListApi(params).then(res => {
            importFile(res.data, "未入住人员列表数据");
            this.fullscreenLoading = false;
          });
        },
        "warning",
        "确定导出表格中的数据吗？"
      );
    },

    // 下载打印
    async dictionaryFun(v) {
      let params = {
        regId: v.id
      };
      let { data } = await downloadEntryBook({ params });
      let url = window.URL.createObjectURL(data);
      window.open(url);
    },

    // 确认签署
    async adsd() {
      let params = {
        regId: this.qdiddd
      };
      let { data } = await confirmEntrySign({ params });
      if (data.code == 200) {
        this.$message.success("确认签署成功");
        this.isConfirmoperation = false;
        this.getListInfo();
      } else {
        this.$message.error(data.msg);
      }
    },
    // 隔离点弹窗确定
    async glddialogqd() {
      let params = {
        regId: this.qdiddd,
        isolationPointId: this.dialogisolationPointId
      };
      let { data } = await replaceIsolationPoint(params);
      if (data.code == 200) {
        this.$message.success("更换成功");
        this.getListInfo();

      } else {
        this.$message.error(data.msg);
      }
      this.isgldd = false
    },
    // 隔离点弹窗取消
    glddialogqx() {
      this.isgldd = false

    }
  }
};
</script>

<style lang='scss' scoped>
@import "../../static/css/condition-filtrate.scss";

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>

<style scoped>
.el-link {
  margin-right: 10px;
}

.el-table>>>td {
  padding: 6px 0 !important;
}

.el-table>>>.cell {
  line-height: 18px;
}

.gleddvi {
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
